import React from "react";
import "./Reseñas.css";

export default function Reseñas() {


  return (
    <div>

    </div>
  );
}
